import React, { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import {
  Drawer,
  Space,
  Select,
  Flex,
  Tag,
  Spin,
  Form,
  Radio,
  DatePicker,
  ConfigProvider,
} from "antd";
import {
  CustomButton,
  ConfirmDialog,
  SuccessDialog,
  FailedDialog,
} from "../../../../components";
import { CampaingService } from "../../../../services/CampaignService";
import locale from "antd/locale/en_US";
import dayjs from "dayjs";
import "dayjs/locale/en";

dayjs.locale("zh-cn");

const { Option } = Select;
const { RangePicker } = DatePicker;

const DrawerSchedule = ({ onClose, open, items, fetchData }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState({});
  const [actionType, setActionType] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenFailed, setIsOpenFailed] = useState(false);

  useEffect(() => {
    form.resetFields();
    if (open) {
      form.setFieldsValue({ type: false });
      setActionType(false);
    }
  }, [open, onClose]);

  const handleUpdateSchedule = async () => {
    try {
      setLoading(true);
      let { getFieldsValue } = form;
      let ids = items.map((item) => item.id);
      let data = {
        ids: ids,
        startDate: getFieldsValue().schedule[0].toISOString(),
        endDate: getFieldsValue().schedule[1].toISOString(),
        isAppendInterval: actionType,
      };
      // console.log("data: ", data);

      await CampaingService.updateSchedule(data);
      setTimeout(() => {
        setLoading(false);
        onClose();
        setIsOpenSuccess(true);
        setTimeout(() => {
          setIsOpenSuccess(false);
          fetchData();
        }, 1000);
      }, 1000);
    } catch (error) {
      console.error();
      setTimeout(() => {
        setLoading(false);
        setIsOpenFailed(true);
      }, 1000);
    }
  };

  // const onOk = (value) => {
  //   setValue({
  //     startDate: value[0]?.toISOString(),
  //     endDate: value[1]?.toISOString(),
  //   });
  // };

  const onFinish = () => {
    setIsOpenConfirm(true);
  };

  return (
    <>
      {/* <ConfigProvider locale={locale}></ConfigProvider> */}
      <Drawer
        title="Change Campaign Schedule"
        placement="right"
        width={470}
        closable={false}
        onClose={() => (onClose(), setActionType())}
        open={open}
        extra={
          <Space>
            <CloseOutlined onClick={() => (onClose(), setActionType())} />
          </Space>
        }
        footer={
          <Flex justify="flex-end">
            <Space>
              <CustomButton
                text="Cancel"
                onClick={() => (onClose(), setActionType())}
                width={100}
              />
              <CustomButton
                text="Save"
                type="primary"
                onClick={() => form.submit()}
                width={100}
              />
            </Space>
          </Flex>
        }
      >
        <Spin spinning={loading} fullscreen />
        <p style={{ fontWeight: "bold" }}>Campaign to change</p>
        <div
          style={{
            width: "410px",
            height: "100px",
            borderRadius: "8px",
            border: "1px solid #D0D5DD",
            margin: "20px 0px",
            padding: "5px",
            overflowY: "scroll",
          }}
        >
          <Space wrap>
            {items.map((item) => (
              <Tag color="default">{item.name}</Tag>
            ))}
          </Space>
        </div>
        <p style={{ fontWeight: "bold" }}>Action Type</p>
        <Form
          hideRequiredMark
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="type"
            rules={[{ required: true, message: "Please select!" }]}
          >
            <Radio.Group
              value={actionType}
              onChange={(e) => setActionType(e.target.value)}
            >
              <Radio value={false}>Replace</Radio>
              <Radio value={true}>Append</Radio>
            </Radio.Group>
          </Form.Item>
          <p style={{ fontWeight: "bold" }}>Schedule</p>
          <Form.Item
            name="schedule"
            rules={[{ required: true, message: "Please select!" }]}
          >
            <RangePicker
              placement="bottomLeft"
              showTime
              format="YYYY-MM-DD HH:mm:ss"
            />
          </Form.Item>
        </Form>
      </Drawer>

      <ConfirmDialog
        isModalOpen={isOpenConfirm}
        onCancel={() => {
          setIsOpenConfirm(false);
        }}
        onConfirm={() => {
          handleUpdateSchedule();
          setIsOpenConfirm(false);
        }}
      />

      <SuccessDialog isModalOpen={isOpenSuccess} />

      <FailedDialog
        isModalOpen={isOpenFailed}
        onCancel={() => {
          setIsOpenFailed(false);
        }}
        onConfirm={() => {
          setIsOpenFailed(false);
          handleUpdateSchedule();
        }}
      />
    </>
  );
};

export default DrawerSchedule;
