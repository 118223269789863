import "./SiderMenu.css";
import { Link } from "react-router-dom";
import {
  BarChartOutlined,
  FileTextOutlined,
  UploadOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";

const SiderMenu = () => {
  return (
    <>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        style={{
          backgroundColor: "transparent",
          width: "190px",
          margin: "auto",
        }}
      >
        <p style={{ color: "#fff", fontWeight: 600, marginBottom: "25px" }}>
          Campaign Management
        </p>
        <Menu.Item key="1" style={{ marginBottom: "10px" }}>
          <Link to="/">
            <FileTextOutlined />
            <span>Voice Campaigns</span>
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="2" style={{ marginBottom: "10px" }}>
          <Link to="/upload">
            <UploadOutlined />
            <span>Upload</span>
          </Link>
        </Menu.Item> */}
        <Menu.Item key="3" style={{ marginBottom: "10px" }}>
          <Link to="/convert-file">
            <UploadOutlined />
            <span>Convert File</span>
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="3" style={{ marginBottom: "10px" }}>
          <Link to="/contact-list">
            <ClearOutlined />
            <span>Contact List</span>
          </Link>
        </Menu.Item> */}
      </Menu>

      {/* <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        className="menu-container"
      >
        <p>Dashboard</p>
        <Menu.Item key="1" className="menu-item">
          <Link to="">
            <BarChartOutlined />
            <span>Dashboard</span>
          </Link>
        </Menu.Item>

        <p>Report</p>
        <Menu.Item key="2" className="menu-item">
          <Link to="">
            <span>System status report</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3" className="menu-item">
          <Link to="">
            <span>PTP report</span>
          </Link>
        </Menu.Item>

        <p>Campaign management</p>
        <Menu.Item key="4" className="menu-item">
          <Link to="/">
            <span>Campaign List</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="5" className="menu-item">
          <Link to="">
            <span>Campaign Tag</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="6" className="menu-item">
          <Link to="">
            <span>Campaign Filter</span>
          </Link>
        </Menu.Item>

        <p>List management</p>
        <Menu.Item key="7" className="menu-item">
          <Link to="">
            <span>DNC List</span>
          </Link>
        </Menu.Item>

        <p>Configuration</p>
        <Menu.Item key="8" className="menu-item">
          <Link to="">
            <span>Setting</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="9" className="menu-item">
          <Link to="">
            <span>Setting Caller address</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="10" className="menu-item">
          <Link to="">
            <span>Contactlist number priority master</span>
          </Link>
        </Menu.Item>

        <p>People management</p>
        <Menu.Item key="11" className="menu-item">
          <Link to="">
            <span>People</span>
          </Link>
        </Menu.Item>
      </Menu> */}
    </>
  );
};

export default SiderMenu;
