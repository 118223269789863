import React, { useState, useEffect } from "react";
import { Table, Checkbox } from "antd";
import "./CustomTable.css";
import CustomPagination from "../Pagination/CustomPagination";

const CustomTable = ({
  columns,
  data,
  // fetchData,
  // getParams,
  loading,
  useRowSelection = false,
  onRowSelectionChange,
  pageSizeOptions,
}) => {
  // const [data, setdata] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [items, setItems] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  // useEffect(() => {
  //   fetchDataWrapper();
  // }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    if (onRowSelectionChange) {
      onRowSelectionChange(items);
    }
  }, [items]);

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: data.length,
      },
    });
    setSelectedRowKeys([]);
    setItems([]);
  }, [data]);

  useEffect(() => {
    const { current, pageSize } = tableParams.pagination;
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setCurrentPageData(data.slice(startIndex, endIndex));
  }, [tableParams, data]);

  // const fetchDataWrapper = async () => {
  //   try {
  //     setLoading(true);
  //     let params = getParams(tableParams);
  //     let response = await fetchData(params);
  //     setdata(response.items);
  //     setTableParams({
  //       ...tableParams,
  //       pagination: {
  //         ...tableParams.pagination,
  //         total: response.items.length,
  //       },
  //     });
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  const handleSelect = (record, selected) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.id]);
      // setItems((keys) => [...keys, { id: record.id, name: record.name }]);
      setItems((keys) => [...keys, record]);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.id);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
      setItems((keys) => keys.filter((item) => item.id !== record.id));
    }
  };

  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) =>
      keys.length === data.length ? [] : data.map((r) => r.id)
    );
    // setItems((keys) =>
    //   keys.length === data.length
    //     ? []
    //     : data.map((r) => ({
    //         id: r.id,
    //         name: r.name,
    //       }))
    // );
    setItems((keys) => (keys.length === data.length ? [] : data.map((r) => r)));
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < data.length
      }
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox",
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
    //onSelectAll: this.handleSelectAll
  };

  const handlePageChange = (page, pageSize, sorter) => {
    if (!sorter) {
      setTableParams({
        pagination: {
          current: page,
          pageSize,
          total: tableParams.pagination?.total,
        },
      });
    }

    // if (pageSize !== tableParams.pagination?.pageSize) {
    //   setData([]);
    // }
  };

  const handlePageSizeChange = (current, pageSize) => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize,
        total: tableParams.pagination?.total,
      },
    });
  };

  return (
    <>
      <div className="custom-table">
        <Table
          style={{ margin: "10px 0" }}
          rowSelection={useRowSelection ? rowSelection : null}
          // columns={sortedColumns}
          columns={columns}
          rowKey={(record) => record.id}
          // dataSource={currentPageData}
          dataSource={data}
          pagination={false}
          // onChange={handlePageChange}
          loading={loading}
          scroll={{ x: 1000 }}
        />

        {data.length !== 0 && (
          <CustomPagination
            // current={tableParams.pagination?.current}
            // pageSize={tableParams.pagination?.pageSize}
            total={tableParams.pagination?.total}
            // loading={loading}
            // onPageChange={handlePageChange}
            // onPageSizeChange={handlePageSizeChange}
            // pageSizeOptions={pageSizeOptions}
          />
        )}
      </div>
    </>
  );
};

export default CustomTable;
