import "./CampaignList.css";
import React, { useEffect, useState } from "react";
import {} from "react-router-dom";
import { SearchOutlined, SyncOutlined, DownOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Divider,
  Dropdown,
  Input,
  Select,
  Tag,
  Tooltip,
  Button,
  Space,
  Flex,
} from "antd";
import { CustomButton, CustomTable } from "../../components";
import {
  DrawerStatus,
  DrawerCallAddress,
  DrawerSchedule,
  DrawerPriorty,
  ModalRecycle,
} from "./Actions";
import { CampaingService } from "../../services/CampaignService";

const { Option } = Select;

const CampaignList = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [items, setItems] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [mode, setMode] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAction, setIsAcion] = useState(true);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isShowCaller, setIsShowCaller] = useState(false);
  const [isShowSchedule, setIsShowSchedule] = useState(false);
  const [isShowPriority, setIsShowPriority] = useState(false);
  const [isShowRecycle, setIsShowRecycle] = useState(false);

  const actionList = [
    {
      key: "1",
      label: <a onClick={() => setIsShowStatus(true)}>Status</a>,
    },
    {
      key: "2",
      label: (
        <a onClick={() => setIsShowCaller(true)}>
          Caller address (phone Number)
        </a>
      ),
    },
    {
      key: "3",
      label: <a onClick={() => setIsShowSchedule(true)}>Schedule</a>,
    },
    {
      key: "4",
      label: <a onClick={() => setIsShowPriority(true)}>Priority campaign</a>,
    },
    {
      key: "5",
      label: <a onClick={() => setIsShowRecycle(true)}>Recycle</a>,
    },
  ];

  const columns = [
    {
      title: "Status",
      // dataIndex: "campaignStatus",
      width: 120,
      render: (_, record) => {
        let color;
        switch (record.campaignStatus.toLowerCase()) {
          case "on":
            color = "green";
            break;
          case "off":
            color = "default";
            break;
          case "complete":
            color = "blue";
            break;
          default:
            color = "default";
        }

        return <Tag color={color}>{record.campaignStatus.toUpperCase()}</Tag>;
      },
      sorter: (a, b) => a.campaignStatus.localeCompare(b.campaignStatus),
    }, 
    // {
    //   title: "Progress",
    //   dataIndex: "",
    // },
    // {
    //   title: "Total contact list count",
    //   dataIndex: "contactlistTotal",
    //   width: 134,
    // },
    // {
    //   title: "Reference Campaign",
    //   dataIndex: "",
    //   width: 228,
    // },
    {
      title: "Campaign name",
      dataIndex: "name",
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Caller Address",
      dataIndex: "callerAddress",
      width: 200,
      sorter: (a, b) => a.callerAddress.localeCompare(b.callerAddress),
    },
    {
      title: "Dialing Mode",
      dataIndex: "dialingMode",
      width: 200,
      sorter: (a, b) => a.dialingMode.localeCompare(b.dialingMode),
    },
    // {
    //   title: "Tag",
    //   dataIndex: "",
    //   width: 200,
    // },
    {
      title: "Contact List",
      dataIndex: "contactListName",
      width: 200,
      sorter: (a, b) => a.contactListName.localeCompare(b.contactListName),
    },
    {
      title: "Division",
      dataIndex: "divisionName",
      width: 224,
      sorter: (a, b) => a.divisionName.localeCompare(b.divisionName),
    },
    {
      title: "Script",
      dataIndex: "scriptName",
      width: 236,
      sorter: (a, b) => a.scriptName.localeCompare(b.scriptName),
    },
    {
      title: "Queue",
      dataIndex: "queueName",
      width: 200,
      sorter: (a, b) => a.queueName.localeCompare(b.queueName),
    },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   width: 100,
    // },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  // const getParams = (params) => ({
  //   Name: keywords,
  // });

  const fetchData = async () => {
    try {
      setLoading(true);
      // let params = getParams();
      let response = await CampaingService.search();
      setData(response.items);
      setFilterData(response.items);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    const filter = data.filter((item) => {
      const matchName = item.name.toLowerCase().includes(keyword.toLowerCase());
      const matchMode =
        mode.length === 0 ||
        mode.includes("all") ||
        mode.includes(item.dialingMode?.toLowerCase());
      return matchName && matchMode;
    });
    setFilterData(filter);
  };

  const handleClearSearch = () => {
    setKeyword("");
    setMode([]);
    setFilterData(data);
  };

  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const handleChangeMode = (value) => {
    if (value.includes("all") && !mode.includes("all")) {
      setMode(["all"]);
    } else {
      const filterMode = value.filter((mode) => mode !== "all");
      setMode(filterMode);
    }
  };

  return (
    <>
      <div className="campaign-container">
        <p
          style={{
            margin: "0px",
            fontSize: "22px",
            fontWeight: 600,
          }}
        >
          Campaign Management
        </p>
        <div
          style={{
            // padding: 24,
            margin: "20px 0px",
            padding: "10px 30px",
            backgroundColor: "#fff",
            borderRadius: "10px",
          }}
        >
          <Row gutter={10} style={{ marginBottom: -6 }}>
            <Col flex="auto">
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Search
              </p>
            </Col>
            <Col flex="495px">
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Dialing mode
              </p>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col flex="auto">
              <Input
                placeholder="search name"
                suffix={
                  <>
                    <SearchOutlined
                    // onClick={handleSearch}
                    />
                  </>
                }
                value={keyword}
                onChange={handleChangeKeyword}
                onPressEnter={handleSearch}
                allowClear
              />
            </Col>
            <Col flex="275px">
              <Select
                mode="tags"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="mode"
                defaultValue={["all"]}
                value={mode}
                onChange={handleChangeMode}
                maxTagCount="responsive"
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    overlayStyle={{
                      pointerEvents: "none",
                    }}
                    title={omittedValues.map(({ label }) => label).join(", ")}
                  >
                    <span>+ {omittedValues.length}</span>
                  </Tooltip>
                )}
              >
                <Option value="all">All</Option>
                <Option value="preview">Preview</Option>
                <Option value="predictive">Predictive</Option>
                <Option value="power">Power</Option>
                <Option value="progressive">Progressive</Option>
                <Option value="agentless">Agentless</Option>
              </Select>
            </Col>
            <Col flex="100px">
              <CustomButton
                text="Clear"
                icon={<SyncOutlined />}
                onClick={handleClearSearch}
                width="100px"
              />
            </Col>
            <Col flex="100px">
              <CustomButton
                text="Search"
                type="primary"
                icon={<SearchOutlined />}
                onClick={handleSearch}
                width="100px"
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 10 }} />
          <Row gutter={10}>
            <Col flex="auto">
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Voice Campaigns
              </p>
            </Col>
            <Col flex="106px" style={{ display: "flex", alignItems: "center" }}>
              <Dropdown
                menu={{
                  items: actionList,
                }}
                disabled={isAction}
                trigger={["click"]}
                // style={{ margin: "16px 0px 0px 0px" }}
              >
                <Button>
                  <Space>
                    Action
                    <MoreOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </Row>
          <CustomTable
            columns={columns}
            data={filterData}
            loading={loading}
            // fetchData={fetchData}
            // getParams={getParams}
            useRowSelection={true}
            onRowSelectionChange={(selectedKeys) => {
              selectedKeys.length !== 0 ? setIsAcion(false) : setIsAcion(true);
              setItems(selectedKeys);
            }}
          />
        </div>
      </div>

      <DrawerStatus
        open={isShowStatus}
        onClose={() => setIsShowStatus(false)}
        items={items}
        fetchData={fetchData}
      />
      <DrawerCallAddress
        open={isShowCaller}
        onClose={() => setIsShowCaller(false)}
        items={items}
        fetchData={fetchData}
      />
      <DrawerSchedule
        open={isShowSchedule}
        onClose={() => setIsShowSchedule(false)}
        items={items}
        fetchData={fetchData}
      />
      <DrawerPriorty
        open={isShowPriority}
        onClose={() => setIsShowPriority(false)}
        items={items}
        fetchData={fetchData}
      />

      <ModalRecycle
        open={isShowRecycle}
        onClose={() => setIsShowRecycle(false)}
        items={items}
        fetchData={fetchData}
      />
    </>
  );
};

export default CampaignList;
